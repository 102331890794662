import { Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

/**
 * @typedef {{}} Props
 * @param {import("react").PropsWithChildren<Props>} props
 */
function ChallengeApp(props) {
  return (
    <Text>
      <FormattedMessage defaultMessage="Entrez le code de vérification de votre application d'authentification à deux facteurs." />
    </Text>
  );
}

export default ChallengeApp;
