// helpers
import apiGetErrorFields from "../getErrorFields";
import apiGetErrorDetail from "../getErrorDetail";

/**
 * Retourne la liste des erreurs ainsi que le détail dans un format compréhensible
 *   pour le hook `useForm`.
 * @param {{
 * error: any,
 * detailFallback: string,
 * }} params
 */
export default function apiGetErrors({ error, detailFallback }) {
  return {
    _detail: apiGetErrorDetail({ error }) ?? detailFallback,
    ...apiGetErrorFields({ error }),
  };
}
