import { Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import Form from "./Form";

function AuthLoginContainer(props) {
  return (
    <Stack spacing="1rem">
      <Text
        color="gray.700"
        fontWeight="700"
        fontSize="1.5rem"
        textAlign="center">
        <FormattedMessage defaultMessage="Accéder à votre console" />
      </Text>

      <Form />
    </Stack>
  );
}

export default AuthLoginContainer;
