/**
 * @param {{
 * payload: any,
 * authenticationType: "standard" | "2-factor" | "2-factor-recovery"
 * }} params
 */
export default function authLoginSerialize({ payload, authenticationType }) {
  return {
    data: {
      ...payload.data,
      code: authenticationType === "2-factor" ? payload.data.code : undefined,
      recovery_code:
        authenticationType === "2-factor-recovery"
          ? payload.data.recovery_code
          : undefined,
    },
  };
}
