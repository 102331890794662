// deps
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useToast } from "@chakra-ui/react";
import useApiFetcher from "./useApiFetcher";

/**
 * Hook utilisé pour faciliter l'envoi des requêtes vers l'API
 *
 * - isLoading: booléen indiquant si une requête est en cours
 * - toastSuccess: callback pour afficher un toast de succès préconfiguré
 */
function useRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const apiFetcher = useApiFetcher();
  const toast = useToast();

  /** @type {(options: import("@chakra-ui/react").UseToastOptions) => void} */
  const toastSuccess = useCallback(
    (options) => {
      toast({
        status: "success",
        position: "bottom",
        duration: 5000,
        isClosable: true,
        ...options,
      });
    },
    [toast],
  );

  /** @type {(options: import("@chakra-ui/react").UseToastOptions) => void} */
  const toastError = useCallback(
    (options) => {
      toast({
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
        ...options,
      });
    },
    [toast],
  );

  /** @type {(url: string, init?: (RequestInit | { body: object }), options?: import("@raiden/library/hooks/useApiFetcher").FetchOptions) => Promise<import("@raiden/library/types/Api/ApiResponse").ApiResponse<any>>} */
  const request = useCallback(
    (url, init, options = {}) => {
      setIsLoading(true);

      return apiFetcher(url, init, options).finally(() => {
        setIsLoading(false);
      });
    },
    [apiFetcher],
  );

  return {
    isLoading,
    request,
    toastSuccess,
    toastError,
  };
}

useRequest.propTypes = {
  children: PropTypes.node,
};

useRequest.defaultProps = {};

export default useRequest;
