// deps
import Head from "next/head";
import Router from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";

// containers
import AuthLogin from "../containers/Auth/Login";

// hooks
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library/constants/users";
import useAuthAs from "@raiden/library/hooks/auth/as";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

export default function LoginPage(props) {
  const intl = useIntl();

  const { logged, loading, user } = useAuthAs();
  useEffect(
    function () {
      if (!loading && logged) {
        if (user?.user_type === USERS_USER_TYPE_VALUE_ADMIN) {
          let redirect = Router.query?.next?.toString?.();
          if (redirect) {
            redirect = redirect.replace(
              process.env.NEXT_PUBLIC_ADMIN_BASE_PATH_CONFIG ?? "",
              "",
            );
            if (!redirect.startsWith("/")) {
              redirect = undefined;
            }
          }
          Router.push(redirect ?? generateAdminPath({ id: "dashboard" }));
        }
        // if (user?.user_type === USERS_USER_TYPE_VALUE_CUSTOMER) {
        //   Router.push(generateFrontUri({ id: "@account" }));
        // }
      }
    },
    [logged, loading, user],
  );

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "raiden.admin.pages.Login.head.title",
            defaultMessage: "Accéder à votre console",
          })}
        </title>

        <meta
          name="description"
          content={intl.formatMessage({
            id: "raiden.admin.pages.Login.head.description",
            defaultMessage: "Page de connexion à votre console.",
          })}
        />
      </Head>

      <AuthLogin />
    </>
  );
}

LoginPage.propTypes = {
  cookies: PropTypes.string,
};

LoginPage.getInitialProps = async function () {
  return {
    layout: "signedOut",
  };
};
